import { React, useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import '../../style/inner_detail.scss';
import { config } from '../../config.js';
import Slider from "react-slick";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { YMaps, Map } from "react-yandex-maps";
import {
    Backdrop,
    Button,
    Box,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    CircularProgress,
    Typography,
    IconButton,
    Toolbar,
    Divider,
} from "@mui/material/";
import { useNavigate, useParams, Link } from 'react-router-dom';
const Iframe = ({ source }) => {

    if (!source) {
        return <div>Loading...</div>;
    }

    const src = source;
    return (
        <iframe src={src} width='100%' height='300' border='none' style={{ border: 'none' }}></iframe>
    );
};
export default function CityGuideDetail() {
    const { id } = useParams()
    const navigate = useNavigate();
    const [data, setData] = useState();
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen(!open);
    };
    const fetchCityDetail = async () => {
        handleToggle();
        fetch(config.apiUrl + "sehir-rehberi-detay/" + id)
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result);
                    setData(result);
                    handleClose();
                },
                (error) => {
                    handleClose();
                    console.error(error);
                }
            )
    };
    useEffect(() => {
        fetchCityDetail();
    }, []);
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    return (
        <div className='firm-detail-inner' key="firma-detay">
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
                onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <AppBar position="static" className='appBar-pages'>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => navigate(-1)}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ flexGrow: 1, display: { sm: 'flex' } }}
                        className='firm-header-title'
                    >
                        {data && data.title}
                    </Typography>
                </Toolbar>
            </AppBar>
            <div>
                <Slider {...settings} className='innerSlider'>
                    <div className="slider-item">
                        <img src={`${config.appUrl}/${data && data.photo}`} className="innerPageSliderItem" alt="slider" />
                    </div>
                </Slider>
            </div>
            <header className="innerPage">
                <Box style={{ maxWidth: '90%',width: '100%' }}>
                    <Card sx={{ width: '100%',  bgcolor: 'background.paper' }} className='firmInnerCards'>
                        <CardContent style={{width:'100%'}}>
                            <Typography gutterBottom variant="h5" component="div" style={{ fontSize: 15, fontWeight: 500 }}>
                                {data && data.title}
                            </Typography>
                                <div dangerouslySetInnerHTML={{ __html: data && data.description }} style={{width:'100%'}}></div>
                        </CardContent>
                    </Card>
                </Box>
            </header>

        </div >
    );
}