import { React, useEffect, useState } from 'react';
import { config } from './config.js';
/* import logo from './logo.svg'; */
import firma_rehberi from './icons/firma-rehberi.svg';
import taksi from './icons/taksi.svg';
import eczaneler from './icons/eczaneler.svg';
import haberler from './icons/haberler.svg';
import koyler from './icons/koyler.svg';
import etkinlikler from './icons/etkinlikler.svg';
import gezi from './icons/gezi.svg';
import ido from './icons/ido.svg';
import './App.scss';
import { SliderContent } from './parts/slider';
import { SearchAppBar } from './parts/header';
/* import { styled } from '@mui/material/styles'; */
import FirmaKategoriPage from './pages/firma/kategori';
import FirmList from './pages/firma/firmList';
import TaxiList from './pages/firma/taxiList';
import FirmDetail from './pages/firma/firmDetail';
import FirmDetailVip from './pages/firma/firmDetailVip';
import TownDetail from './pages/koyler/detay';
import TownList from './pages/koyler/liste';
import EczanePage from './pages/eczaneler/eczane';
import IdoSeferleriPage from './pages/ido/ido';
import NewsList from './pages/haberler/liste';
import NewsDetail from './pages/haberler/detay';
import EventsList from './pages/etkinlikler/liste';
import EventsDetail from './pages/etkinlikler/detay';
import TravelDetail from './pages/gezilecekmekanlar/detay';
import TravelList from './pages/gezilecekmekanlar/liste';
import IletisimPage from './pages/iletisim/iletisim';
import CekGonderPage from './pages/iletisim/cekgonder';
import CityGuideList from './pages/sehrim/liste';
import CityGuideDetail from './pages/sehrim/detay';
import moment from 'moment'
import 'moment/locale/tr'  
import {
  Avatar,
  Button,
  Box,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Dialog,
} from "@mui/material/";
import { BrowserRouter as Router, Route, Routes, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { motion } from "framer-motion";
const PageLayout = ({ children }) => children;

const pageVariants = {
  initial: {
    opacity: 0
  },
  in: {
    opacity: 1
  },
  out: {
    opacity: 0
  }
};

const pageTransition = {
  type: "tween",
  ease: "linear",
  duration: 0.5
};

const AnimationLayout = () => {
  const { pathname } = useLocation();
  return (
    <PageLayout>
      <motion.div
        key={pathname}
        initial="initial"
        animate="in"
        variants={pageVariants}
        transition={pageTransition}
      >
        <Outlet/>
      </motion.div>
    </PageLayout>
  );
};
let isObject = function(a) {
  return (!!a) && (a.constructor === Object);
};
function App() {
  
  const [homeadPhoto, setHomeAdPhoto] = useState('jpg');
  const [homead, setHomeAd] = useState();
  const fetchHomeAd = async () => {
    fetch(config.apiUrl + "anasayfa-reklam")
      .then(res => res.json())
      .then(
        (result) => {
          setHomeAd(result);
          console.info(result);
          setHomeAdPhoto(result.photo)
          if(isObject(result)){
            setOpen(true);
          }
        },
        (error) => {
        }
      )
  };
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    fetchHomeAd();
    setTimeout(() => {
      handleClose()
    }, 5000);
  }, []);
  return (
    <div>
    <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
      >
        <Button variant="contained" className='skipAdBtn' onClick={() => handleClose()}>REKLAMI GEÇ</Button>
        <img src={config.appUrl + '/'+ homeadPhoto} style={{width:'100%',height:'100vh'}} alt="main-popup-ad"/>
      </Dialog>
    {homead && <Router>
      <div>
        <Routes>
          <Route element={<AnimationLayout />}>
            <Route path="/" element={<Home />} />
            <Route path="/eczaneler" element={<EczanePage />} />
            <Route path="/idoseferleri" element={<IdoSeferleriPage />} />
            <Route path="/firmakategori" element={<FirmaKategoriPage />} />
            <Route path="/firmalistesi/:catid" element={<FirmList />} />
            <Route path="/firmadetay/:id" element={<FirmDetail />} />
            <Route path="/firmadetayvip/:id" element={<FirmDetailVip />} />
            <Route path="/takilistesi" element={<TaxiList />} />
            <Route path="/koydetay/:id" element={<TownDetail />} />
            <Route path="/koylistesi" element={<TownList />} />
            <Route path="/haberler" element={<NewsList />} />
            <Route path="/haberler/:id" element={<NewsDetail />} />
            <Route path="/etkinlikler" element={<EventsList />} />
            <Route path="/etkinlikler/:id" element={<EventsDetail />} />
            <Route path="/iletisim" element={<IletisimPage />} />
            <Route path="/cekgonder" element={<CekGonderPage />} />
            <Route path="/gezilecekmekanlar" element={<TravelList />} />
            <Route path="/gezilecekmekanlar/:id" element={<TravelDetail />} />
            <Route path="/sehirrehberi" element={<CityGuideList />} />
            <Route path="/sehirrehberi/:id" element={<CityGuideDetail />} />
          </Route>
        </Routes>
      </div>
    </Router>}
    </div>
  );
}
function Home() {
  const navigate = useNavigate();
  window.navigate = navigate;
  const [news, setNews] = useState([]);
  const [featuredFirms, setFeaturedFirms] = useState([]);
  const [homeAltAd, setHomeAltAd] = useState();
  const fetchHomeAltAd = async () => {
    fetch(config.apiUrl + "anasayfa-alt-reklam")
      .then(res => res.json())
      .then(
        (result) => {
          if(isObject(result)){
            setHomeAltAd(result);
          }
        },
        (error) => {
        }
      )
  };
  const fetchFeaturedFirms = async () => {
    fetch(config.apiUrl + "anasayfa-one-cikan-firmalar")
      .then(res => res.json())
      .then(
        (result) => {
          console.info(result)
          setFeaturedFirms(result);
        },
        (error) => {
        }
      )
  };
  const fetchNews = async () => {
    fetch(config.apiUrl + "haberler-anasayfa")
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result)
          setNews(result);
        },
        (error) => {
        }
      )
  };
  useEffect(() => {
    fetchNews();
    fetchHomeAltAd();
    fetchFeaturedFirms();
  }, []);
  return <div className="App">
    
    <div className='main_content_area'>
      <SearchAppBar />
      <SliderContent />
    </div>
    <header className="App-header">
      {/* <Typography gutterBottom variant="h6" component="h6" className='section_fast_title' style={{ marginTop: '1rem', marginBottom: '1rem' }}>
        Hızlı İşlemler
      </Typography> */}
      <Box className='main_content_outer' style={{ marginTop: '1rem' }}>
        <Box className='main_content_box'>
          <Button variant="outlined" className='fast_buttons_home' onClick={() => navigate("/firmakategori")}>
            <img src={firma_rehberi} alt="Firma" />
            Firmalar
          </Button>
          <Button variant="outlined" className='fast_buttons_home' onClick={() => navigate("/haberler")}>
            <img src={haberler} alt="Haberler" />
            Haberler
          </Button>
          <Button variant="outlined" className='fast_buttons_home' onClick={() => navigate("/takilistesi")}>
            <img src={taksi} alt="Taksi" />
            Taksi
          </Button>
          <Button variant="outlined" className='fast_buttons_home' onClick={() => navigate("/eczaneler")}>
            <img src={eczaneler} alt="Eczaneler" />
            Nöb. Ecz.
          </Button>
          <Button variant="outlined" className='fast_buttons_home' onClick={() => navigate("/koylistesi")}>
            <img src={koyler} alt="Köyler" />
            Köyler
          </Button>
          <Button variant="outlined" className='fast_buttons_home' onClick={() => navigate("/etkinlikler")}>
            <img src={etkinlikler} alt="Etkinlikler" />
            Etkinlikler
          </Button>
          <Button variant="outlined" className='fast_buttons_home' onClick={() => navigate("/gezilecekmekanlar")}>
            <img src={gezi} alt="Gezi Rehberi" />
            Gezi
          </Button>
          <Button variant="outlined" className='fast_buttons_home' onClick={() => navigate("/idoseferleri")}>
            <img src={ido} alt="İDO" />
            İDO
          </Button>
        </Box>
      </Box>
      <Typography gutterBottom variant="h6" component="h6" className='section_title'>
        Haberler
      </Typography>
      <Box className='home_news_section'>
        <div className="scrollable-h">
          {news && news.map((value) => {
            const labelId = `list-secondary-label-${value.id}`;
            return (
              <Card className='newsCard home' style={{ marginBottom: '10px' }} onClick={() => navigate("/haberler/" + value.id)} key={labelId}>
                <CardMedia
                  component="img"
                  alt={value && value.name}
                  height="150"
                  image={`${config.appUrl}/${value.photo}`}
                  style={{ objectPosition: 'top' }}
                />
                <CardContent style={{ padding: '16px' }}>
                  <Typography gutterBottom variant="h6" component="div" style={{ fontSize: '13px',overflow:'hidden' }}>
                    {value && value.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" style={{ fontSize: '11px' }}>
                    {value && moment(value.date).format('DD-MM-YYYY')}
                  </Typography>
                </CardContent>
              </Card>
            );
          })}
        </div>
      </Box>
      <Box style={{padding:'0 22px'}}>
        {isObject(homeAltAd) && <img src={`${config.appUrl}/${homeAltAd.photo}`} alt="secondary-ad" onClick={() => {window.open(homeAltAd.url, '_blank').focus();}} style={{maxWidth:'100%',borderRadius:'10px',height:'200px',objectFit:'cover',maxHeight:'200px',width:'100%'}}/>}
      </Box>
      <Typography gutterBottom variant="h6" component="h6" className='section_title'>
        Öne Çıkan Firmalar
      </Typography>
      <Box className='home_firms_section'>
        <div className="scrollable-h">
          {featuredFirms && featuredFirms.map((value) => {
            const labelId = `list-secondary-label-${value.id}`;
            return (
              <Card className='homeFirmCard' style={{ marginBottom: '10px' }} onClick={() => navigate("/firmadetayvip/" + value.id)} key={labelId}>
                <CardMedia
                  component="img"
                  alt={value && value.name}
                  height="120"
                  className='firm-bg'
                  image={`${config.appUrl}/${value.photo && value.photo.name}`}
                />
                <CardContent style={{ padding: '16px 1px' }} className='container_content'>
                  <div className='primary_div'>
                  <Avatar alt={value && value.name} src={`${config.appUrl}/${value.logo && value.logo}`} variant="rounded" className='firm-logo'/>
                  </div>
                  <div className='secondary_div'>
                  <Typography gutterBottom variant="h6" component="div" style={{ fontSize: '13px',maxWidth:'calc(100% - 20px)',overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap' }}>
                    {value && value.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" style={{ fontSize: '11px',maxWidth:'calc(100% - 50px)',overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap' }}>
                    {value && value.description.replace(/(<([^>]+)>)/ig, '')}
                  </Typography>
                  </div>
                </CardContent>
              </Card>
            );
          })}
        </div>
      </Box>
    </header></div>;
}
export default App;