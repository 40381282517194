import { React, useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import '../../style/eczaneler.scss';
import { config } from '../../config.js';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Button,
    Typography,
    IconButton,
    Toolbar,
} from "@mui/material/";
import { useNavigate, Link } from 'react-router-dom';
const Iframe = ({ source }) => {

    if (!source) {
        return <div>Lütfen bekleyin...</div>;
    }

    const src = source;
    return (
        <iframe src={src} width='100%' height='500' border='none' style={{border:'none',height:'90vh'}}></iframe>
    );
};
export default function EczanePage() {
    const navigate = useNavigate();
    const [pharmacy, setPharmacy] = useState();
    const fetchPharmacy = async () => {
        fetch(config.apiUrl + "eczane-bilgileri")
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result)
                    setPharmacy(result);
                },
                (error) => {
                    console.error(error)
                }
            )
    };
    useEffect(() => {
        fetchPharmacy();
    }, []);
    return (
        <div className='big-div-content'>
            <AppBar position="static" className='appBar-pages'>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        sx={{ mr: 2 }}
                        onClick={() => navigate(-1)}
                        className='appBarBackButton'
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ flexGrow: 1, display: { sm: 'flex' } }}
                        className='appBarHeaderTitlePages'
                    >
                        Nöbetçi Eczaneler
                    </Typography>
                </Toolbar>
            </AppBar>
            <header className="App-header-pages">
                <Card sx={{ maxWidth: '100%' }}>
                    <CardContent>
                        <Iframe source={pharmacy && pharmacy.pharmacy_url} />
                    </CardContent>
                    {/* <CardActions>
                        <Button size="small">Share</Button>
                        <Button size="small">Learn More</Button>
                    </CardActions> */}
                </Card>
            </header>
        </div >
    );
}