import { React, useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import '../../style/inner_category.scss';
import { config } from '../../config.js';
/* import { SliderContent } from '../../parts/slider'; */
/* import { SearchAppBar } from '../../parts/header';
import { styled } from '@mui/material/styles'; */
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
    Alert,
    Avatar,
    Button,
    Box,
    ListItem,
    ListItemText,
    List,
    ListItemButton,
    ListItemAvatar,
    Typography,
    IconButton,
    Toolbar,
    Skeleton,
} from "@mui/material/";
import { useNavigate, Link } from 'react-router-dom';

function startsWith(str, word) {
    return str.lastIndexOf(word, 0) === 0;
}
export default function FirmaKategoriPage() {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filterLength, setFilterLength] = useState(3);
    const handleClose = () => {
        setLoading(false);
    };
    const handleToggle = () => {
        setLoading(!loading);
    };
    const fetchCategories = async () => {
        fetch(config.apiUrl + "firma-kategorileri")
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result);
                    setData(result);
                },
                (error) => {
                    console.error(error);
                }
            )
    };
    const filterFirms = async (text) => {
        setFilterLength(text.length);
        console.log(filterLength);
        handleToggle();
        fetch(config.apiUrl + "firma-listesi-filtrele/" + text)
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result);
                    setData(result);
                    if (result.length < 1) {
                        setFilterLength(0);
                    }
                    handleClose();
                },
                (error) => {
                    console.error(error);
                    handleClose();
                }
            )
    };
    useEffect(() => {
        // 1 saniye sonra kaydırma işlemini gerçekleştir bu olay ios da beyaz ekran verdiği için yapıldı.
        //Çok aradım ancak neden bulunamadı...
        const scrollTimeout = setTimeout(() => {
            window.scrollTo(0, 1);
        }, 500);
        fetchCategories();
        return () => {
            clearTimeout(scrollTimeout);
        };
    }, []); 
    return (
        <div className='big-div-content'>
            <AppBar position="static" className='appBar-pages'>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => navigate(-1)}
                        className='appBarBackButton'
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ flexGrow: 1, display: { sm: 'flex' } }}
                        className='appBarHeaderTitlePages'
                    >
                        Firma Kategorileri
                    </Typography>
                </Toolbar>
            </AppBar>
            <div className='innerPage-header-bottom'>
                <input type='text' placeholder='Ara...' onKeyUp={(el) => filterFirms(el.target.value)}></input>
            </div>
            <header className="App-header-pages">
                {loading &&
                    <Box sm={{ maxWidth: '90%', justifyContent: 'center' }} className='loadingSkeletonFirmList'>
                        <Skeleton variant="circular" width={40} height={40} />
                        <Skeleton variant="rectangular" width='80%' height={40} />
                    </Box>
                }
                {loading &&
                    <Box sm={{ maxWidth: '90%', justifyContent: 'center', marginTop: '1rem' }} className='loadingSkeletonFirmList'>
                        <Skeleton variant="circular" width={40} height={40} />
                        <Skeleton variant="rectangular" width='80%' height={40} />
                    </Box>
                }
                {loading &&
                    <Box sm={{ maxWidth: '90%', justifyContent: 'center', marginTop: '1rem' }} className='loadingSkeletonFirmList'>
                        <Skeleton variant="circular" width={40} height={40} />
                        <Skeleton variant="rectangular" width='80%' height={40} />
                    </Box>
                }
                {!loading && filterLength >= 3 &&
                    <List dense sx={{ width: '100%', maxWidth: '100vw', bgcolor: 'background.paper', padding: '10' }} className='innerPageListOuter'>
                        <ListItem
                            key='keyCekGonder'
                            elevation={1}
                            secondaryAction={
                                <IconButton edge="end" aria-label="comments">
                                    <ArrowForwardIosIcon />
                                </IconButton>
                            }
                            className={'innerPageListItem vip cekgonder'}
                            disablePadding
                            onClick={() => navigate('/cekgonder')}
                        >
                            <ListItemButton className='innerPageListItemInner'>
                                <ListItemAvatar>
                                    <Avatar alt='asdsad' src='/cekgonder.svg' />
                                </ListItemAvatar>
                                <ListItemText id='listItemIndex' primary='Çek Gönder' secondary='Siz de firmanızın bu listede olmasını istiyorsanız hemen iletişime geçin.' />
                            </ListItemButton>
                        </ListItem>
                        {data.map && data.map((value) => {
                            const labelId = `checkbox-list-secondary-label-${value.id}`;
                            return (
                                <ListItem
                                    key={value.id}
                                    elevation={1}
                                    secondaryAction={
                                        <IconButton edge="end" aria-label="comments">
                                            <ArrowForwardIosIcon />
                                        </IconButton>
                                    }
                                    className={value.is_vip == true ? 'innerPageListItem vip' : 'innerPageListItem'}
                                    disablePadding
                                    onClick={() => value.category_id != null ? navigate(value.is_vip == true ? "/firmadetayvip/" + value.id : "/firmadetay/" + value.id) : navigate("/firmalistesi/" + value.id)}
                                >
                                    <ListItemButton className='innerPageListItemInner'>
                                        <ListItemAvatar>
                                            {value.category_id != null && <Avatar alt={`${value.name}`} src={`${config.appUrl}/${value.photo && value.photo.name}`} />}
                                            {value.category_id == null && <Avatar alt={`${value.name}`} src={`${config.appUrl}/${value.photo}`} />}
                                        </ListItemAvatar>
                                        {value.category_id != null && <ListItemText id={labelId} primary={`${value.name}`} secondary={value.is_vip == true ? value.address : ''} />}
                                        {value.category_id == null && <ListItemText id={labelId} primary={`${value.name}`} />}
                                    </ListItemButton>
                                </ListItem>
                            );
                        })}
                    </List>
                }
                {!loading && filterLength < 3 &&
                    <Box style={{ maxWidth: '100%', justifyContent: 'center', marginTop: '1rem', flexDirection: 'column', alignItems: 'center' }} className='loadingSkeletonFirmList'>
                        <Alert severity="error">Aradığınız kriterlerde veri bulunamadı</Alert>
                        <Button variant="outlined" onClick={() => { fetchCategories(); setFilterLength(3) }}>Filtreyi Sıfırla</Button>
                    </Box>
                }
            </header>
        </div >
    );
}