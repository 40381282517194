import { React, useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import '../../style/inner_category.scss';
import { config } from '../../config.js';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PhoneIcon from '@mui/icons-material/Phone';
import {
    Alert,
    Avatar,
    Button,
    ListItem,
    ListItemText,
    List,
    ListItemIcon,
    ListItemButton,
    ListItemAvatar,
    Typography,
    IconButton,
    Toolbar,
    Box,
    Skeleton,
} from "@mui/material/";
import { useNavigate, useParams, Link } from 'react-router-dom';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function TaxiList() {
    const catid = 11;
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [catData, setCatData] = useState();
    const [loading, setLoading] = useState(false);
    const handleClose = () => {
        setLoading(false);
    };
    const handleToggle = () => {
        setLoading(!loading);
    };
    const fetchCategory = async () => {
        handleToggle();
        fetch(config.apiUrl + "kategori-detay/" + catid)
            .then(res => res.json())
            .then(
                (result) => {
                    setCatData(result);
                    handleClose();
                },
                (error) => {
                    handleClose();
                }
            )
    };
    const fetchFirms = async () => {
        handleToggle();
        await fetch(config.apiUrl + "firma-listesi/" + catid)
            .then(res => res.json())
            .then(
                (result) => {
                    setData(result);
                    handleClose();
                },
                (error) => {
                    handleClose();
                }
            )
    };
    const filterFirms = async (text) => {
        handleToggle();
        fetch(config.apiUrl + "firma-listesi/" + catid + "/" + text)
            .then(res => res.json())
            .then(
                (result) => {
                    setData(result);
                    handleClose();
                },
                (error) => {
                    handleClose();
                }
            )
    };
    useEffect(() => {
        fetchCategory();
        fetchFirms();
    }, []);
    function FilterFirms() {
        if (data !== null) {
            console.log(data);
            return (<List dense sx={{ width: '100%', maxWidth: '100vw', bgcolor: 'background.paper', padding: '10' }} className='innerPageListOuter'>
            {data && data.map((value) => {
                const labelId = `checkbox-list-secondary-label-${value.id}`;
                return (
                    <ListItem
                        key={value.id}
                        elevation={1}
                        /* secondaryAction={
                            <IconButton edge="end" aria-label="comments">
                                <ArrowForwardIosIcon />
                            </IconButton>
                        } */
                        className={value.is_vip == true ? 'innerPageListItem vip' : 'innerPageListItem'}
                        disablePadding
                        onClick={() => window.location.href = 'tel:'+value.phone}
                    >
                        <ListItemButton className='innerPageListItemInner taksiInnerBtn'>
                            <ListItemIcon style={{justifyContent:'center',paddingRight:'12px'}}>
                                <Avatar variant="square" alt={value.name} src="hizli-arama.svg" />
                                {/* <PhoneIcon /> */}
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={`${value.name}`} secondary={value.address} />
                        </ListItemButton>
                    </ListItem>
                );
            })}
        </List>);
        }
    }
    return (
        <div className='big-div-content'>
            <AppBar position="static" className='appBar-pages'>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => navigate(-1)}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ flexGrow: 1, display: { sm: 'flex' } }}
                        className='appBarHeaderTitlePages'
                        style={{ padding: '0' }}
                    >
                        {catData && catData.name}
                    </Typography>
                </Toolbar>
            </AppBar>
            <div className='innerPage-header-bottom'>
                <input type='text' placeholder='Ara...' onChange={(el) => filterFirms(el.target.value)}></input>
            </div>
            <header className="App-header-pages">
                {loading &&
                    <Box sm={{ maxWidth: '90%', justifyContent: 'center' }} className='loadingSkeletonFirmList'>
                        <Skeleton variant="circular" width={40} height={40} />
                        <Skeleton variant="rectangular" width='80%' height={40} />
                    </Box>
                }
                {loading &&
                    <Box sm={{ maxWidth: '90%', justifyContent: 'center', marginTop: '1rem' }} className='loadingSkeletonFirmList'>
                        <Skeleton variant="circular" width={40} height={40} />
                        <Skeleton variant="rectangular" width='80%' height={40} />
                    </Box>
                }
                {loading &&
                    <Box sm={{ maxWidth: '90%', justifyContent: 'center', marginTop: '1rem' }} className='loadingSkeletonFirmList'>
                        <Skeleton variant="circular" width={40} height={40} />
                        <Skeleton variant="rectangular" width='80%' height={40} />
                    </Box>
                }
                {!loading &&
                    <FilterFirms/>
                }
            </header>
        </div >
    );
}