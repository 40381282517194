import {React, useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import '../../style/inner_detail.scss';
import {config} from '../../config.js';
import Slider from "react-slick";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PublicIcon from '@mui/icons-material/Public';
import {
    Backdrop,
    Button,
    ButtonGroup,
    Box,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    CircularProgress,
    Typography,
    IconButton,
    Toolbar,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Divider
} from "@mui/material/";
import {useNavigate, useParams, Link} from 'react-router-dom';
import {YMaps, Map, Placemark} from "react-yandex-maps";
const Iframe = ({source}) => {

    if (!source) {
        return <div>Loading...</div>;
    }

    const src = source;
    return (
        <iframe
            src={src}
            width='100%'
            height='300'
            border='none'
            style={{
            border: 'none'
        }}></iframe>
    );
};
export default function FirmDetailVip() {
    const {id} = useParams()
    const navigate = useNavigate();
    const [data,
        setData] = useState();
    const [photos,
        setPhotos] = useState();
    const [open,
        setOpen] = useState(false);
    const [lang,
        setLang] = useState("tr_TR");
    const handleClose = () => {
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen(!open);
    };
    const fetchFirmDetail = async() => {
        handleToggle();
        fetch(config.apiUrl + "firma-detay/" + id)
            .then(res => res.json())
            .then((result) => {
                console.log(result);
                setData(result);
            }, (error) => {
                console.error(error);
            })
        fetch(config.apiUrl + "firma-fotograflari/" + id)
            .then(res => res.json())
            .then((result) => {
                console.log(result);
                setPhotos(result);

                handleClose();
            }, (error) => {
                console.error(error);
            })
    };
    useEffect(() => {
        fetchFirmDetail();
    }, []);
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    const mapState = { center: [data && data.locationx, data && data.locationy], zoom: 14 };
    return (
        <div className='firm-detail-inner'>
            <Backdrop
                sx={{
                color: '#fff',
                zIndex: (theme) => theme.zIndex.drawer + 1
            }}
                open={open}
                onClick={handleClose}>
                <CircularProgress color="inherit"/>
            </Backdrop>
            <AppBar position="static" className='appBar-pages'>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => navigate(-1)}>
                        <ArrowBackIcon/>
                    </IconButton>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{
                        flexGrow: 1,
                        display: {
                            sm: 'flex'
                        }
                    }}
                        className='firm-header-title'>
                        {data && data.name}
                    </Typography>
                </Toolbar>
            </AppBar>

            <header className="innerPage">
                <Box style={{
                    maxWidth: '90%'
                }}>
                    <div>
                        <Slider {...settings} className='innerSlider'>
                            {photos && photos.map((value) => {
                                return (
                                    <div className="slider-item">
                                        <img
                                            src={`${config.appUrl}/${value.name}`}
                                            className="innerPageSliderItem"
                                            alt="slider"/>
                                    </div>
                                );
                            })}
                        </Slider>
                    </div>
                    <div>
                        <Card
                            sx={{
                            width: '100%',
                            maxWidth: 360,
                            bgcolor: 'background.paper',
                            marginTop: '1rem'
                            }}
                            className='firmVipTopCard vip'>
                            <CardContent className='firmInnerCardTop'>
                                {data && <img src={`${config.appUrl}/${data.logo}`}/>}
                                <div>
                                    <Typography
                                        gutterBottom
                                        variant="h5"
                                        component="div"
                                        style={{
                                        fontSize: 13
                                    }}>
                                        {data && data.name}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        style={{
                                        fontSize: 12
                                    }}>
                                        {data && data.address}
                                    </Typography>
                                    {data && data.website === null && <Button variant="contained" size="small" className='firm_site_btn' onClick={() => window.location.href = data.website != undefined ? data.website : 'https://google.com'} style={{fontSize:'11px',marginTop:'9px',fontWeight:'400',textTransform:'unset'}}>Websitesini Görüntüle</Button>}
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                    
                    <Card
                        sx={{
                        width: '100%',
                        maxWidth: 360,
                        bgcolor: 'background.paper'
                    }}
                        className='firmInnerCards'>
                        <CardContent>
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                style={{
                                fontSize: 12
                            }}>
                                {data && <div dangerouslySetInnerHTML={{ __html: data.description }} />}
                            </Typography>
                        </CardContent>
                    </Card>
                    <Box
                        sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginTop: '1rem'
                    }}>
                        <Box className='firmInnerButtonGroup'>
                            <Button key="one" startIcon={< PhoneIcon />} className='firmInnerButton' onClick={() => window.location.href = ('tel:'+data.phone)}>{data && data.phone}</Button>
                            <Button key="three" startIcon={< WhatsAppIcon />} className='firmInnerButton' onClick={() => window.location.href = ('https://wa.me/'+data.whatsapp ?? '')}>Whatsapp</Button>
                            {config.platform == 'ios' ? 
                            <Button key="two" startIcon={<LocationOnIcon />} className='firmInnerButton' onClick={() => window.location.href = ('maps://?q='+data.map+'&daddr='+data.map+'&dirflg=d')}>Yol Tarifi</Button>
                            :
                            <Button key="two" startIcon={<LocationOnIcon />} className='firmInnerButton' onClick={() => window.location.href = ('https://www.google.com/maps/dir/?api=1&destination='+data.map)}>Yol Tarifi</Button>
                            }
                        </Box>
                    </Box>
                    
                    {data && <Card
                        sx={{
                        width: '100%',
                        maxWidth: 360,
                        bgcolor: 'background.paper',
                        marginTop: '1rem'
                    }}
                        className='firmInnerCards'>
                        <CardContent>
                            <YMaps
                                key={lang}
                                query={{
                                lang
                            }}>
                                <Map state={mapState}>
                                    <Placemark
                                    geometry={[data && data.locationx, data && data.locationy]}
                                    properties={{
                                        hintContent: 'Yol Tarifi',
                                        balloonContent: 'Yol Tarifi'
                                    }}
                                    options={{
                                        iconLayout: 'default#image',
                                        iconImageHref: 'placemark.png',
                                        iconImageSize: [30, 42],
                                        iconImageOffset: [-3, -42]
                                    }}
                                    />
                                    </Map>
                            </YMaps>
                        </CardContent>
                    </Card>
}
                </Box>
            </header>
        </div >
    );
}