import * as React from 'react';
import {config} from '../../config.js';
import {useState} from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import EmailIcon from '@mui/icons-material/Email';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import logo from '../../icons/logo.svg';
import '../../style/iletisim.scss';
import {
    Alert,
    Avatar,
    Button,
    CircularProgress,
    IconButton,
    Snackbar,
    Typography,
    Toolbar
} from "@mui/material/";
import {useNavigate, useParams, Link} from 'react-router-dom';
const theme = createTheme();
export default function IletisimPage() {
    const navigate = useNavigate();
    const [loading,
        setLoading] = React.useState(false);
    const [open,
        toggleSidebar] = useState(false);
    const [snackopen,
        setOpen] = useState(false);
    const [snackText,
        setSnackText] = useState('Bilgi');
    const handleOpen = (event) => {
        setOpen(true)
    };
    const handleClose = (event) => {
        setOpen(false)
    };
    const handleSnackText = (text) => {
        setSnackText(text)
    };
    function handleLoading() {
        setLoading(true);
    }
    function disableLoading() {
        setLoading(false);
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        handleLoading();
        const data = new FormData(event.currentTarget);
        console.info(data.get('photo'));
        console.log({
            name: data.get('name'),
            mail: data.get('mail'),
            message: data.get('message'),
            phone: data.get('phone'),
            photo: data.get('photo')
        });
        const requestOptions = {
            method: 'POST',
            body: data
        };
        fetch(config.apiUrl + 'cekgonder-gonder', requestOptions)
            .then(response => response.json())
            .then((result) => {
                console.log(result)
                handleSnackText(result)
                handleOpen();
                disableLoading();
            })
    };
    return (
        <ThemeProvider theme={theme}>
            <Snackbar
                open={snackopen}
                onClose={handleClose}
                message={snackText}
                key='testt'
                autoHideDuration={5000}/>
            <AppBar position="static" className='app_header_iletisim'>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => navigate(-1)}>
                        <ArrowBackIcon/>
                    </IconButton>
                    <Box
                        sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%'
                    }}><img
                        src={logo}
                        alt="Logo"
                        height={40}
                        style={{
            marginTop: '5px',maxWidth:'150px'
        }}/></Box>
                    <Box sx={{
                        flexGrow: 1
                    }}/>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => console.log('search clicked')}></IconButton>
                </Toolbar>
            </AppBar>
            <Container component="main" maxWidth="xs">
                <CssBaseline/>

                <Box
                    sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <Avatar
                        sx={{
                        m: 1,
                        bgcolor: 'primary.main'
                    }}>
                        <EmailIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Çek Gönder
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        noValidate
                        sx={{
                        mt: 1
                    }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="name"
                            label="Ad & Soyad"
                            name="name"
                            autoFocus/>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="mail"
                            label="E-Posta Adresiniz"
                            type="email"
                            id="mail"/>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="phone"
                            label="Telefon Numaranız"
                            type="tel"
                            id="phone"/>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="message"
                            label="Mesajınız"
                            type="text"
                            id="message"
                            multiline
                            rows={4}/>
                            
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="photo"
                            label="Fotoğraf"
                            type="file"
                            name="photo"
                            variant="standard"
                            />
                            <Alert severity="error">Lütfen göndermek istediğiniz fotoğrafı yatay olarak çekin.</Alert>
                        <Button
                            type="submit"
                            fullWidth
                            disabled = {loading}
                            variant="contained"
                            sx={{
                            mt: 3,
                            mb: 2
                        }}>
                            {loading && <CircularProgress
                                style={{
                                width: '24px',
                                height: '24px',
                                color: 'white'
                            }}/>}
                            {loading == true
                                ? ''
                                : 'GÖNDER'}
                        </Button>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}