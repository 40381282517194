import { React, useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import '../../style/inner_category.scss';
import { config } from '../../config.js';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SearchIcon from '@mui/icons-material/Search';
import {
    Alert,
    Avatar,
    Button,
    ListItem,
    ListItemText,
    List,
    ListItemButton,
    ListItemAvatar,
    Typography,
    IconButton,
    Toolbar,
    Modal,
    Box,
    Backdrop,
    CircularProgress,
    Stack,
    Skeleton,
} from "@mui/material/";
import { useNavigate, useParams, Link } from 'react-router-dom';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function FirmList() {
    const { catid } = useParams()
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [catData, setCatData] = useState();
    const [loading, setLoading] = useState(false);
    const handleClose = () => {
        setLoading(false);
    };
    const handleToggle = () => {
        setLoading(!loading);
    };
    const fetchCategory = async () => {
        handleToggle();
        fetch(config.apiUrl + "kategori-detay/" + catid)
            .then(res => res.json())
            .then(
                (result) => {
                    setCatData(result);
                    handleClose();
                },
                (error) => {
                    handleClose();
                }
            )
    };
    const fetchFirms = async () => {
        handleToggle();
        await fetch(config.apiUrl + "firma-listesi/" + catid)
            .then(res => res.json())
            .then(
                (result) => {
                    setData(result);
                    handleClose();
                },
                (error) => {
                    handleClose();
                }
            )
    };
    const filterFirms = async (text) => {
        handleToggle();
        fetch(config.apiUrl + "firma-listesi/" + catid + "/" + text)
            .then(res => res.json())
            .then(
                (result) => {
                    setData(result);
                    handleClose();
                },
                (error) => {
                    handleClose();
                }
            )
    };
    useEffect(() => {
          // 0.5 saniye sonra kaydırma işlemini gerçekleştir bu olay ios da beyaz ekran verdiği için yapıldı.
        //Çok aradım ancak neden bulunamadı...
        const scrollTimeout = setTimeout(() => {
            window.scrollTo(0, 1);
        }, 500);
        fetchCategory();
        fetchFirms();
        return () => {
            clearTimeout(scrollTimeout);
        }; 
    }, []);
    function FilterFirms() {
        if (data !== null) {
            console.log(data);
            return (<List dense sx={{ width: '100%', maxWidth: '100vw', bgcolor: 'background.paper', padding: '10' }} className='innerPageListOuter'>
                {data && data.map((value) => {
                    const labelId = `checkbox-list-secondary-label-${value.id}`;
                    return (
                        <ListItem
                            key={value.id}
                            elevation={1}
                            secondaryAction={
                                <IconButton edge="end" aria-label="comments">
                                    <ArrowForwardIosIcon />
                                </IconButton>
                            }
                            className={value.is_vip == true ? 'innerPageListItem vip' : 'innerPageListItem'}
                            disablePadding
                            onClick={() => navigate(value.is_vip == true ? "/firmadetayvip/" + value.id : "/firmadetay/" + value.id)}
                        >
                            <ListItemButton className='innerPageListItemInner'>
                                <ListItemAvatar>
                                    <Avatar
                                        alt={`${value.name}`}
                                        src={`${config.appUrl}/${value.photo && value.photo.name}`}
                                    />
                                </ListItemAvatar>
                                <ListItemText id={labelId} primary={`${value.name}`} secondary={value.is_vip == true ? value.address : ''} />
                            </ListItemButton>
                        </ListItem>
                    );
                })}
            </List>);
        }
    }
    return (
        <div className='big-div-content'>
            {/* <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop> */}
            <AppBar position="static" className='appBar-pages'>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => navigate(-1)}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ flexGrow: 1, display: { sm: 'flex' } }}
                        className='appBarHeaderTitlePages'
                        style={{ padding: '0' }}
                    >
                        {catData && catData.name}
                    </Typography>
                </Toolbar>
            </AppBar>
            <div className='innerPage-header-bottom'>
                <input type='text' placeholder='Ara...' onChange={(el) => filterFirms(el.target.value)}></input>
            </div>
            <header className="App-header-pages">
                {loading &&
                    <Box sm={{ maxWidth: '90%', justifyContent: 'center' }} className='loadingSkeletonFirmList'>
                        <Skeleton variant="circular" width={40} height={40} />
                        <Skeleton variant="rectangular" width='80%' height={40} />
                    </Box>
                }
                {loading &&
                    <Box sm={{ maxWidth: '90%', justifyContent: 'center', marginTop: '1rem' }} className='loadingSkeletonFirmList'>
                        <Skeleton variant="circular" width={40} height={40} />
                        <Skeleton variant="rectangular" width='80%' height={40} />
                    </Box>
                }
                {loading &&
                    <Box sm={{ maxWidth: '90%', justifyContent: 'center', marginTop: '1rem' }} className='loadingSkeletonFirmList'>
                        <Skeleton variant="circular" width={40} height={40} />
                        <Skeleton variant="rectangular" width='80%' height={40} />
                    </Box>
                }
                {!loading &&
                    <FilterFirms />
                }
            </header>
        </div >
    );
}