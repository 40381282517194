import { React, useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import '../../style/eczaneler.scss';
import { config } from '../../config.js';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Button,
    Typography,
    IconButton,
    Toolbar,
} from "@mui/material/";
import { useNavigate, Link } from 'react-router-dom';
const Iframe = ({ source }) => {

    if (!source) {
        return <div>Yükleniyor...</div>;
    }

    const src = source;
    return (
        <iframe src={src} width='100%' height='500' border='none' style={{border:'none',minHeight: 'calc(100vh - 80px)'}}></iframe>
    );
};
export default function IdoSeferleriPage() {
    const navigate = useNavigate();
    return (
        <div className='big-div-content'>
            <AppBar position="static" className='appBar-pages'>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        sx={{ mr: 2 }}
                        onClick={() => navigate(-1)}
                        className='appBarBackButton'
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ flexGrow: 1, display: { sm: 'flex' } }}
                        className='appBarHeaderTitlePages'
                    >
                        İDO Seferleri
                    </Typography>
                </Toolbar>
            </AppBar>
            <header className="App-header-pages">
                <Card sx={{ maxWidth: '100%' }}>
                    <CardContent>
                        <Iframe source='https://www.ido.com.tr/sefer-saatleri' />
                    </CardContent>
                </Card>
            </header>
        </div >
    );
}