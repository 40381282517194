import { React, useEffect, useState } from 'react';
import Slider from "react-slick";
import { config } from '../config.js';
import { useNavigate, Link } from 'react-router-dom';
export function SliderContent() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const fetchAds = async () => {
    fetch(config.apiUrl + "slider-reklamlari")
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result);
          setData(result);
        },
        (error) => {
          console.error(error);
        }
      )
  };
  useEffect(() => {
    fetchAds();
  }, []);
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000
  };
  return (
    <Slider {...settings} className='mainSlider'>
      {data.map((value) => {
        return (
          <div className="slider-item" key={value.id}>
            <img src={`${config.appUrl}/${value.photo}`} className="sliderImage" alt="slider" onClick={() => {window.open(value.url, '_blank').focus();}}/>
          </div>
        );
      })}

    </Slider>
  );
}