import { React, useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import '../../style/inner_detail.scss';
import { config } from '../../config.js';
import Slider from "react-slick";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import moment from 'moment'
import 'moment/locale/tr'  
import {
    Backdrop,
    Button,
    Box,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    CircularProgress,
    Typography,
    IconButton,
    Toolbar,
    Divider,
} from "@mui/material/";
import { useNavigate, useParams, Link } from 'react-router-dom';
export default function NewsDetail() {
    const { id } = useParams()
    const navigate = useNavigate();
    const [data, setData] = useState();
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen(!open);
    };
    const fetchNewsDetail = async () => {
        handleToggle();
        fetch(config.apiUrl + "haber-detay/" + id)
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result);
                    setData(result);
                    handleClose();
                },
                (error) => {
                    console.error(error);
                    handleClose();
                }
            )
    };
    useEffect(() => {
        fetchNewsDetail();
    }, []);
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    return (
        <div className='firm-detail-inner' key="firma-detay">
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
                onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <AppBar position="static" className='appBar-pages'>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => navigate(-1)}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ flexGrow: 1, display: { sm: 'flex' } }}
                        className='firm-header-title'
                    >
                        {data && data.name}
                    </Typography>
                </Toolbar>
            </AppBar>
            <header className="innerPage">
                <Box style={{ maxWidth: '90%' }}>
                    <Card sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', marginBottom: '16px' }} className='firmInnerCards'>
                        {data && <CardMedia
                            component="img"
                            alt={data && data.name}
                            image={`${config.appUrl}/${data.photo}`}
                            style={{ objectPosition: 'top' }}
                        />}
                        <CardContent style={{ paddingBottom: '16px' }}>
                            <Typography gutterBottom variant="h5" component="div" style={{ fontSize: 15, fontWeight: 500 }}>
                                {data && data.name}
                            </Typography>
                            <Typography variant="body2" color="text.secondary" style={{ fontSize: 12 }}>
                                {data && moment(data.date).format('DD-MM-YYYY')}
                            </Typography>
                        </CardContent>
                    </Card>
                    <Card sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} className='firmInnerCards'>
                        <CardContent>
                            <Typography variant="body2" color="text.secondary" style={{ fontSize: 12 }}>
                                {data && <div dangerouslySetInnerHTML={{ __html: data.content }} />}
                            </Typography>
                        </CardContent>
                    </Card>
                </Box>
            </header>

        </div >
    );
}