import { React, useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import '../../style/inner_category.scss';
import { config } from '../../config.js';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
    Alert,
    Avatar,
    Button,
    ListItem,
    ListItemText,
    List,
    ListItemButton,
    ListItemAvatar,
    Typography,
    IconButton,
    Toolbar,
    Box,
    Skeleton,
} from "@mui/material/";
import { useNavigate, useParams, Link } from 'react-router-dom';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function TownList() {
    const { catid } = useParams()
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filterText, setFilterText] = useState('');
    const handleClose = () => {
        setLoading(false);
    };
    const handleToggle = () => {
        setLoading(!loading);
    };
    const fetchFirms = async () => {
        handleToggle();
        fetch(config.apiUrl + "koy-listesi")
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result)
                    setData(result);
                    handleClose();
                },
                (error) => {
                    handleClose();
                }
            )
    };
    useEffect(() => {
        fetchFirms();
    }, []);
    return (
        <div className='big-div-content'>
            <AppBar position="static" className='appBar-pages'>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => navigate(-1)}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ flexGrow: 1, display: { sm: 'flex' } }}
                        className='appBarHeaderTitlePages'
                        style={{ padding: '0' }}
                    >
                        Köy Listesi
                    </Typography>
                </Toolbar>
            </AppBar>
            <div className='innerPage-header-bottom'>
                <input type='text' placeholder='Ara...' onChange={(el) => setFilterText(el.target.value)}></input>
            </div>
            <header className="App-header-pages">
                {loading &&
                    <Box sm={{ maxWidth: '90%', justifyContent: 'center'}} className='loadingSkeletonFirmList' style={{marginTop:'1rem'}}>
                        <Skeleton variant="circular" width={40} height={40} />
                        <Skeleton variant="rectangular" width='80%' height={40} />
                    </Box>
                }
                {loading &&
                    <Box sm={{ maxWidth: '90%', justifyContent: 'center', marginTop: '1rem' }} className='loadingSkeletonFirmList'>
                        <Skeleton variant="circular" width={40} height={40} />
                        <Skeleton variant="rectangular" width='80%' height={40} />
                    </Box>
                }
                {loading &&
                    <Box sm={{ maxWidth: '90%', justifyContent: 'center', marginTop: '1rem' }} className='loadingSkeletonFirmList'>
                        <Skeleton variant="circular" width={40} height={40} />
                        <Skeleton variant="rectangular" width='80%' height={40} />
                    </Box>
                }
                {!loading &&
                    <List dense sx={{ width: '100%', maxWidth: '100vw', bgcolor: 'background.paper', padding: '10' }} className='innerPageListOuter'>
                        {data && data.filter(veri => veri.name.toLowerCase().includes(filterText.toLowerCase())).map(value => (
                            <ListItem
                            key={value.id}
                            elevation={1}
                            secondaryAction={
                                <IconButton edge="end" aria-label="comments">
                                    <ArrowForwardIosIcon />
                                </IconButton>
                            }
                            className={value.is_vip == true ? 'innerPageListItem vip' : 'innerPageListItem'}
                            disablePadding
                            onClick={() => navigate("/koydetay/"+value.id)}
                            >
                            <ListItemButton className='innerPageListItemInner'>
                                <ListItemAvatar>
                                    <Avatar
                                        alt={`${value.name}`}
                                        src={`${config.appUrl}/${value.photo && value.photo.name}`}
                                    />
                                </ListItemAvatar>
                                <ListItemText id={value.id} primary={`${value.name}`}/*  secondary={`${value.name}`} */ />
                            </ListItemButton>
                        </ListItem>
                        ))}
                    </List>
                }
            </header>
        </div >
    );
}