import { React, useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import '../../style/inner_detail.scss';
import { config } from '../../config.js';
import Slider from "react-slick";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { YMaps, Map } from "react-yandex-maps";
import {
    Backdrop,
    Button,
    Box,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    CircularProgress,
    Typography,
    IconButton,
    Toolbar,
    Divider,
} from "@mui/material/";
import { useNavigate, useParams, Link } from 'react-router-dom';
const Iframe = ({ source }) => {

    if (!source) {
        return <div>Yükleniyor...</div>;
    }

    const src = source;
    return (
        <iframe src={src} width='100%' height='300' border='none' style={{ border: 'none' }}></iframe>
    );
};
export default function TravelDetail() {
    const { id } = useParams()
    const navigate = useNavigate();
    const [data, setData] = useState();
    const [photos, setPhotos] = useState();
    const [open, setOpen] = useState(false);
    const [lang, setLang] = useState("tr_TR");
    const handleClose = () => {
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen(!open);
    };
    const fetchTownDetail = async () => {
        handleToggle();
        fetch(config.apiUrl + "gezilecek-mekan/" + id)
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result);
                    setData(result);
                },
                (error) => {
                    console.error(error);
                }
            )
        fetch(config.apiUrl + "gezilecek-mekan-fotograflari/" + id)
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result);
                    setPhotos(result);
                    handleClose();
                },
                (error) => {
                    console.error(error);
                }
            )
    };
    useEffect(() => {
        fetchTownDetail();
    }, []);
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    return (
        <div className='firm-detail-inner' key="firma-detay">
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
                onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <AppBar position="static" className='appBar-pages'>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => navigate(-1)}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ flexGrow: 1, display: { sm: 'flex' } }}
                        className='firm-header-title'
                    >
                        {data && data.title}
                    </Typography>
                </Toolbar>
            </AppBar>
            <div>
                <Slider {...settings} className='innerSlider'>
                    {photos && photos.map((value) => {
                        return (
                            <div className="slider-item">
                                <img src={`${config.appUrl}/${value.name}`} className="innerPageSliderItem" alt="slider" />
                            </div>
                        );
                    })}
                </Slider>
            </div>
            <header className="innerPage">
                <Box style={{ maxWidth: '90%',minwidth:'90%',width: '-webkit-fill-available' }}>
                    <Card sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} className='firmInnerCards'>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div" style={{ fontSize: 15, fontWeight: 500 }}>
                                {data && data.title}
                            </Typography>
                            <Typography variant="body2" color="text.secondary" style={{ fontSize: 12 }}>
                                {data && data.description}
                            </Typography>
                        </CardContent>
                    </Card>
                    {data && data.coords !== null &&
                        <Box className='firmInnerButtonGroup' style={{marginTop:'16px'}}>
                            {config.platform == 'ios' ? 
                            <Button variant="text" startIcon={<LocationOnIcon />} className='firmInnerButton w-100' onClick={() => window.location.href = ('maps://?q='+data.coords+'&daddr='+data.coords+'&dirflg=d')}>Yol Tarifi Al</Button>
                            :
                            <Button variant="text" startIcon={<LocationOnIcon />} className='firmInnerButton w-100' onClick={() => window.location.href = ('https://www.google.com/maps/dir/?api=1&destination='+data.coords)}>Yol Tarifi Al</Button>
                            }
                        </Box>
                    }
                </Box>
            </header>
        </div >
    );
}